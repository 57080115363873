<template>
  <div 
    id="header" 
    class="border-b header_js fixed top-0 z-10 w-full"
    :class="[ headerBorder, headerBg ]"
  >
    <template>
      <div class="logo border-r border-black flex items-center justify-between" :class="[ linkBorderColor ]">
        <img src="../assets/logo.svg" />
      </div>
      <header class="flex items-center justify-between w-full">
        <nav class="h-full">
          <ul class="h-full">
            <li class="border-r h-full inline-block" :class="[ linkBorderColor ]">
              <router-link to="/" class="base-font-gray-100 no-underline h-full flex items-center px-2">Dashboard</router-link>
            </li>
            <li class="border-r h-full inline-block" :class="[ linkBorderColor ]">
              <router-link to="/buckets" class="base-font-gray-100 no-underline h-full flex items-center px-2">Buckets</router-link>
            </li>
            <li class="border-r h-full inline-block" :class="[ linkBorderColor ]">
              <router-link 
                to="/discovery" 
                class="base-font-gray-100 no-underline h-full flex items-center px-2"
                :class="{
                  'discovery': discovery
                }"
              >
                Discovery
              </router-link>
            </li>
            <li class="border-r h-full inline-block" :class="[ linkBorderColor ]">
              <router-link to="/search" class="base-font-gray-100 no-underline h-full flex items-center px-2">Video Search</router-link>
            </li>
            <!-- <li class="border-r h-full" :class="[ linkBorderColor ]">
              <router-link to="/track" class="base-font-gray-100 no-underline h-full flex items-center px-2">Track</router-link>
            </li>
            <li class="border-r h-full" :class="[ linkBorderColor ]">
              <router-link to="/trends" class="base-font-gray-100 no-underline h-full flex items-center px-2">Trends</router-link>
            </li> -->
          </ul>
        </nav>
        <div class="mr-36 relative">
          <div 
            class="flex items-center justify-between w-80 h-8 border border-purple-light rounded-lg px-4 cursor-pointer"
            :class="[ selectedBucket.bucketId ? 'purple-light-bg-200' : '' ]"
            @click="showBucketWindow"
            ref="bucketButton"
          >
            <div v-if="selectedBucket.bucketId" class="flex">
              <span class="font-sans text-xs purple-text">Active Bucket</span> 
              <span class="font-sans text-xs text-black pl-2">{{ selectedBucket.title | truncate(24) }}</span>
            </div>
            <span v-else class="font-sans text-xs base-font-gray-100">
              No bucket selected
            </span>
            <chevronTopIcon 
              class="text-black transition-all"
              :class="[ isShowBucketWindow ? 'rotate-180' : '' ]"
            />
          </div>
          <div 
            v-if="isShowBucketWindow"
            class="absolute top-full right-0 mt-2 flex bucket-window purple-light-bg-200 rounded-lg"
            v-click-outside="showBucketWindow" 
          >
            <div class="w-1/3 h-full border-r border-purple-100">
              <div class="flex items-center pl-6 h-20 border-b border-purple-100">
                <div>
                  <p class="font-sans text-sm purple-text w-full">Active Bucket</p>
                  <p class="font-sansDemi text-sm text-black mt-2 w-full whitespace-nowrap">
                    <span v-if="selectedBucket.bucketId">
                      {{ selectedBucket.title | truncate(18)  }}
                    </span>
                    <span v-else>None</span>
                  </p>
                </div>
              </div>
              <div class="pl-6">
                <ul>
                  <li 
                    v-for="item in bucketsNames"
                    :key="item.key"
                    @click="setActiveBucket(item)"
                    class="font-sans text-sm w-full cursor-pointer mt-5"
                    :class="[ (activeBucket && activeBucket.key == item.key) ? 'text-black' : 'purple-100' ]"
                  >
                    {{ item.name }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="w-2/3 h-full">
              <div 
                v-if="activeBucket"
                class="h-full"
              >
                <div class="flex items-center w-full pl-3 h-20 border-b border-dashed border-purple-100">
                  <ul v-if="selectedBucket.bucketId">
                    <li 
                      v-for="item in topList"
                      :key="item.key"
                      @click="setActiveTopItem(item)"
                      class="font-sans text-xs cursor-pointer pl-3 inline-block"
                      :class="[ (activeTopItem && activeTopItem.key == item.key) ? 'text-black' : 'purple-100' ]"
                    >
                      {{ item.name }} 
                      <template v-if="item.key == topList[0].key && channels.length">
                        ({{ channels.length }})
                      </template>
                      <template v-if="isLoadingNewVideos && item.key == topList[1].key">
                        <Loader 
                          :animation="true"
                          classes="w-4 h-4"
                        />
                      </template>
                    </li>
                  </ul>
                </div>
                <div class="list-block">
                  <template v-if="activeTopItem">
                    <channelsList 
                      v-if="activeTopItem.key == topList[0].key" 
                      :list="channels"
                      class="overflow-y-auto h-full"
                    />
                    <videosList 
                      v-if="activeTopItem.key == topList[1].key" 
                      :list="videos"
                      class="overflow-y-auto h-full"
                      @lastItem="lastVideo"
                    />
                    <div 
                      v-if="activeTopItem.key == topList[2].key"
                      class="flex items-center justify-center w-full h-full"
                    >
                      <span class="font-sansDemi text-sm text-black">Coming Soon</span>
                    </div>
                  </template>
                  <ul v-else class="overflow-y-auto h-full">
                    <li
                      v-for="item in buckets[activeBucket.key]"
                      :key="item.bucketId"
                      class="w-full p-0.5"
                    >
                      <div
                        class="w-full flex items-center justify-between"
                        :class="[ { 'purple-light-bg rounded': selectedBucket.bucketId == item.bucketId } ]"
                      >
                        <div class="mx-4 py-4">
                          <p class="font-sansDemi text-sm text-black">
                            {{ item.name }}
                            <span
                              v-if="item.deepStorage"
                              class="font-monoDemi text-xs-mini text-white rounded py-0.5 px-1 purple-bg"
                            >
                              Storage
                            </span>
                          </p>
                          <p class="font-sansDemi text-sm base-font-gray-200 mt-1">{{ item.description }}</p>
                        </div>
                        <div class="mr-4">
                          <button 
                            v-if="item.deepStorage"
                            class="h-6 font-monoDemi border border-purple-200 inline-block text-xs-max px-3 rounded-2xl whitespace-nowrap text-black"
                          >
                            Activate
                          </button>
                          <button 
                            v-if="!item.deepStorage && selectedBucket.bucketId != item.bucketId"
                            class="h-6 font-monoDemi border border-purple-200 inline-block text-xs-max px-3 rounded-2xl whitespace-nowrap text-black"
                            @click.stop="setBucket({
                              bucketId: item.bucketId,
                              title: item.name,
                              bucketType: activeBucket.key,
                              deepStorage: item.deepStorage
                            })"
                          >
                            Select
                          </button>
                        </div>
                      </div>
                      <div class="mx-4 mt-1 border-b border-dashed border-purple-100"></div>
                    </li>
                  </ul>
                </div>
              </div>
              <div 
                v-else
                class="w-full h-full flex items-center justify-center"
              >
                <span class="font-sansDemi text-xs purple-text">Please select a bucket</span>
              </div>
            </div>
          </div>
        </div>

        <!-- <a 
          href="https://je9lx0qew25.typeform.com/to/HMsyfJuh" 
          target="_blank"
          class="hidden md:inline base-font-gray-light-200 text-xs border-grey-light-100 rounded-md px-4 py-1 mr-48"
        > 
          Bug Report 
        </a> -->
        
      </header>
    </template>
  </div>
</template>

<script>
import chevronTopIcon from '@/components/icons/chevron-top.vue'
import { mapGetters, mapMutations } from 'vuex';
import channelsList from '@/components/top-buckets-select/channels-list'
import videosList from '@/components/top-buckets-select/videos-list'
import Loader from '@/components/Loader'

export default {
  'name': 'FeatureNav',
  props: {
    headerBorder: {
      type: String,
      default: 'border-black'
    },
    linkBorderColor: {
      type: String,
      default: ''
    },
    headerBg: {
      type: String,
      default: 'bg-white'
    },
    discovery: {
      type: Boolean,
      default: false
    },
  },
  components: {
    chevronTopIcon,
    channelsList,
    videosList,
    Loader
  },
  data(){
    return {
      buckets: [],
      bucketsNames: [
        { key: 'privateBuckets', name: 'Private Buckets' },
        { key: 'teamBuckets', name: 'Team Buckets' },
        { key: 'communityBuckets', name: 'Community Buckets' }
      ],
      topList: [
        { key: 'channels', name: 'Channels' },
        { key: 'videos', name: 'Videos' },
        { key: 'indexing_progression', name: 'Indexing Progression' },
      ],
      activeBucket: null,
      activeTopItem: null,
      channels: [],
      videos: [],
      isShowBucketWindow: false,
      videosTimeline: {
        start: this.$moment().subtract(7, 'days').unix(),
        end: this.$moment().unix()
      },
      isLoadingNewVideos: false
    }
  },
  methods: {
    ...mapMutations({
      setSelectedBucket: 'general/setSelectedBucket'
    }),
    async lastVideo(){
      this.isLoadingNewVideos = true
      const videos = await this.loadMoreVideos()     
      console.log(videos, 'videos')  
      if(!videos.length) await this.loadMoreVideos() 
      this.isLoadingNewVideos = false
    },
    async loadMoreVideos(){
      this.videosTimeline.end = this.videosTimeline.start
      this.videosTimeline.start = this.$moment.unix(this.videosTimeline.start).subtract(7, 'days').unix()

      try {
        const videos = await this.getVideos()
        this.videos = this.videos.concat(videos)
        return videos
      } catch (error) {
        console.log(error)
        return []
      }
    },
    setBaseTimeLine(){
      this.videosTimeline.start = this.$moment().subtract(7, 'days').unix(),
      this.videosTimeline.end = this.$moment().unix()
    },
    async setBucket(item){
      this.setSelectedBucket(item)
      this.channels = []
      this.videos = []
      this.setBaseTimeLine()

      await this.getChannels()
      await this.getVideos()
      this.setSelectedBucket({ ...item, channels: this.channels.length })
    },
    async getBuckets() {
      this.buckets = (await this.$axios.get(`${process.env.VUE_APP_BASE_URL}/api/v1/get-bucket`)).data.readAccess
    },
    async getChannels(){
      this.channels = (await this.$axios.get(`${process.env.VUE_APP_BASE_URL}/api/v1/get_channels_bucket/${this.selectedBucket.bucketId}`)).data
    },
    async getVideos(){
      const { data } = await this.$axios.get(`${process.env.VUE_APP_BASE_URL}/api/v1/get-videos-bucket/${this.selectedBucket.bucketId}/min/${this.videosTimeline.start}/max/${this.videosTimeline.end}`)
      this.videos = JSON.parse(JSON.stringify(data))
      return data
    },
    async showBucketWindow(){
      if(!this.isShowBucketWindow){
        setTimeout(() => {
          this.isShowBucketWindow = true
        }, 0);
        await this.getBuckets()
        if(this.selectedBucket.bucketId){
          await this.getChannels()
          await this.getVideos()
        }
      }else this.isShowBucketWindow = false
    },
    setActiveBucket(item){
      this.activeBucket = JSON.parse(JSON.stringify(item))
      this.activeTopItem = null
    },
    setActiveTopItem(item){
      this.activeTopItem = JSON.parse(JSON.stringify(item))
    },
    showBucketPickerInactive() {
      this.$refs.bucketButton.classList.add('bucket-warning');
      setTimeout(() => this.$refs.bucketButton.classList.remove('bucket-warning'), 1000)            
    }
  },
  computed: {
    ...mapGetters({
      selectedBucket: 'general/selectedBucket'
    })
  },
  created() {
    this.$root.$refs.featureNav = this;
  },
  async mounted() {
    if ('bid' in this.$route.params) {
      const { data } = await this.$axios.get(`${process.env.VUE_APP_BASE_URL}/api/v1/get-solo-bucket/${this.$route.params.bid}`)

      this.setSelectedBucket({
        bucketId: data.bucketId,
        bucketType: `${data.altType}Buckets`,
        title: data.name,
        deepStorage: data.deepStorage,
        channels: data.channels
      })
      
      const bucketType = this.bucketsNames.find(elem => elem.key == `${data.altType}Buckets`)
      if(bucketType) {
        this.setActiveBucket(bucketType)
        this.setActiveTopItem(this.topList[0])
      }
    }
  }
};
</script>

<style scoped lang="scss">
#header {
  height: 3rem;
  // margin-left: -3rem;
  display: flex;
}
.rotate-180{
  transform: rotate(180deg);
}
.bucket-window{
  width: 528*1.25px;
  height: 374px;
  .list-block{
    height: calc(100% - 80px);
  }
}
  .logo {
    line-height: 3rem;
    width: 8rem;
    padding: 0 1.5rem;
    // border-right: 1px solid black;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      font-family: 'Ilisarniq';
      font-weight: 'normal';
      text-decoration: none;
    }
  }



#header {
  a {
    &.router-link-exact-active {
      &.discovery{
        color: #fff;
      }
      color: #000;
      font-family: Ilisarniq Demi, sans-serif;
    }
  }
}

@keyframes bg-pulsating {
  0% {
    // outline: 1px solid rgba(226, 226, 226, 0);
    background-color: rgb(0, 0, 0);
  }
  100% {
    // outline: 1px solid rgba(226, 226, 226, 0);
    // color: black;
    background-color: rgba(255, 255, 255, 1);


  }
}

.bucket-warning {
  // background: black;
  // color: white;
  animation-name: bg-pulsating;
  animation-duration: 1s; 
  animation-iteration-count: 1;

}


</style>